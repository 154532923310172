$bp-medium: 56.25em; // 900px
$bp-small: 37.5em; // 600px
$bp-smallest: 31.25em; // 500px

.main {
  margin-top: 10rem;
  min-height: calc(100vh - 27.4rem);
}

@media only screen and (max-width: $bp-medium) {
  .main {
    margin-top: 7.8rem;
    min-height: calc(100vh - 20.5rem);
  }
}
