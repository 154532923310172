$bp-medium: 56.25em; // 900px

.carousel {
  ol[class^='carousel-indicators'] {
    margin-bottom: 0;

    & li {
      width: 0.8rem;
      height: 0.8rem;
      background-color: #e8ebf6;
      opacity: 1;
      border-radius: 50%;
      margin-left: 0;
      border-top: 0.2rem solid transparent;
      border-bottom: 0.2rem solid transparent;

      &[class='active'] {
        background-color: #4554d6;
      }

      &:not(:last-child) {
        margin-right: 2rem;
      }
    }
  }

  a[class='carousel-control-prev'] {
    top: auto;
    z-index: 16;
    opacity: 1;
    left: 40%;
    transform: translateX(-50%);
    margin-bottom: 1px;
    width: 3rem;

    [class='carousel-control-prev-icon'] {
      width: 0.5rem;
      height: 1rem;
      background: url('./assets/prev.svg') no-repeat center;
      background-size: contain;
    }
  }

  a[class='carousel-control-next'] {
    top: auto;
    z-index: 16;
    opacity: 1;
    right: 40%;
    transform: translateX(50%);
    margin-bottom: 1px;
    width: 3rem;

    [class='carousel-control-next-icon'] {
      width: 0.5rem;
      height: 1rem;
      background: url('./assets/next.svg') no-repeat center;
      background-size: contain;
    }
  }

  .item {
    margin-bottom: 3.8rem;
  }
}

@media only screen and (max-width: $bp-medium) {
  .carousel {
    display: none;
  }
}
