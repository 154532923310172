$bp-medium: 56.25em; // 900px
$bp-smallest: 31.25em; // 500px

.sponsors {
  margin-top: 10rem;
  margin-bottom: 10rem;
  text-align: center;

  h2 {
    font-weight: 600;
    font-size: 4rem;
    line-height: 6rem;
    margin-bottom: 2rem;
  }

  p {
    max-width: 52.8rem;
    display: inline-block;
    line-height: 2.4rem;
    margin-bottom: 4rem;
  }

  .logos {
    .row {
      max-width: 104rem;
      margin: 0 auto 2rem;
      height: 6.9rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:last-child {
        margin: 0 auto;
      }

      .world {
        width: 13.86rem;
        height: 5.2rem;
        background: url('./assets/world-gym.svg') no-repeat center;
        background-size: cover;
      }

      .ufc {
        width: 10.35rem;
        height: 6.2rem;
        background: url('./assets/ufc.svg') no-repeat center;
        background-size: contain;
      }

      .golds {
        width: 18rem;
        height: 1.3rem;
        background: url('./assets/golds-gym.svg') no-repeat center;
        background-size: contain;
      }

      .crunch {
        width: 9.694rem;
        height: 6.1rem;
        background: url('./assets/crunch.svg') no-repeat center;
        background-size: contain;
      }

      .cc {
        width: 8.08rem;
        height: 6.9rem;
        background: url('./assets/cc.svg') no-repeat center;
        background-size: contain;
      }
    }
  }
}

@media only screen and (max-width: $bp-medium) {
  .sponsors {
    margin-top: 3.8rem;
    margin-bottom: 6rem;

    h2 {
      font-size: 2.6rem;
      margin-bottom: 1.6rem;
      line-height: 2.9rem;
    }

    .logos {
      .row {
        min-height: 2.874rem;
        justify-content: space-evenly;
        margin: 0 auto 3.2rem;

        .hide {
          display: none;
        }

        .world {
          width: 6.424rem;
          height: 2.41rem;
        }

        .ufc {
          width: 4.799rem;
          height: 2.874rem;
        }

        .golds {
          width: 8.343rem;
          height: 0.605rem;
        }

        .crunch {
          width: 4.493rem;
          height: 2.827rem;
        }
      }
    }
  }
}

@media only screen and (max-width: $bp-smallest) {
  .sponsors {
    p {
      max-width: 29.1rem;
    }

    .logos {
      .row {
        justify-content: space-between;
      }
    }
  }
}
