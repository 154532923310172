$bp-medium: 56.25em; // 900px

$color-bg: #e8ebf6;

h3 {
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 3.2rem;
  margin-bottom: 1.4rem;
}

.card {
  border-radius: 0.8rem;
  background-color: $color-bg;
  padding: 2.8rem 2.4rem;

  h4 {
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 2.4rem;
    margin-bottom: 0;
  }

  p {
    margin-bottom: 2.4rem;
    line-height: 2.4rem;
  }

  .description {
    p {
      margin-bottom: 0;
    }
  }
}

@media only screen and (max-width: $bp-medium) {
  h3 {
    font-size: 1.6rem;
    line-height: 3.6rem;
    margin-bottom: 0.6rem;
  }

  .card {
    padding: 2.4rem 2rem;
  }
}
