$bp-largest: 75em; // 1200px
$bp-large: 68.75em; // 1100px
$bp-medium: 56.25em; // 900px
$bp-small: 37.5em; // 600px
$bp-smallest: 31.25em; // 500px

$color-link: #0d1744;
$color-link-active: #4554d6;
$color-border: rgba(#0d1744, 0.1);

.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  height: 10rem;
  border-bottom: 1px solid $color-border;

  .navbar {
    max-width: 136rem;
    margin: 0 auto;
    display: flex;
    align-items: center;

    .nav {
      flex-grow: 1;

      display: flex;
      justify-content: space-between;
      margin: 0 9rem;
      height: 2.4rem;

      & > a {
        text-decoration: none;
      }

      .selected {
        text-decoration: none;
        .navLink {
          color: $color-link-active;
        }
      }

      .navLink {
        font-family: 'Gilroy', sans-serif;
        font-weight: 600;
        text-transform: uppercase;
        font-size: 2rem;
        line-height: 2.4rem;
        color: $color-link;
        cursor: pointer;
        white-space: nowrap;
        transition: all 0.3s ease;

        &:hover {
          color: $color-link-active;
        }
      }
    }

    .sign {
      font-family: 'Gilroy', sans-serif;
      font-weight: 600;
      height: 6rem;
      text-transform: uppercase;
      border-radius: 8px;
      font-size: 2rem;
      background: #4554d6;
      border-color: #4554d6;
      padding: 1.8rem 5rem;
      line-height: 1rem;

      &:hover {
        background-color: #0069d9;
        border-color: #0062cc;
      }
    }

    .togler {
      width: 2.4rem;
      height: 1.6rem;
      background: url('./assets/togler.svg') no-repeat center;
      background-size: contain;
      cursor: pointer;
      transition: all 0.3s ease;
      display: none;

      &:hover {
        opacity: 0.6;
      }
    }
  }
}

@media only screen and (max-width: $bp-largest) {
  .header {
    .navbar {
      .nav {
        .navLink {
          font-size: 1.6rem;
        }
      }
      .sign {
        font-weight: normal;
        font-size: 1.6rem;
        padding: 1.8rem 4rem;
      }
    }
  }
}

@media only screen and (max-width: $bp-large) {
  .header {
    .navbar {
      .nav {
        .navLink {
          font-size: 1.2rem;
        }
      }
      .sign {
        height: 4.6rem;
        font-size: 1.2rem;
        padding: 1.4rem 3rem;
      }
    }
  }
}

@media only screen and (max-width: $bp-medium) {
  .header {
    height: 7.8rem;
    .navbar {
      justify-content: space-between;
      .nav,
      .sign {
        display: none;
      }
      .togler {
        display: block;
      }
    }
  }
}
