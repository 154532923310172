$bp-medium: 56.25em; // 900px

$primary-color: #4554d6;
$error-color: #eb5757;

.auth {
  position: fixed;
  z-index: 90;
  top: 0;
  min-height: 100vh;
  width: 100%;
  background-color: #fff;
  display: flex;

  .gradient {
    max-width: 61.7rem;
    flex-grow: 1;
    overflow: hidden;

    display: flex;
    justify-content: center;
    align-items: center;
    background: radial-gradient(
      73.45% 73.45% at 15% 50%,
      rgba(69, 84, 214, 0.2) 0%,
      rgba(247, 249, 255, 0.05) 100%
    );
  }

  .sign {
    position: relative;
    flex-grow: 1;

    display: flex;
    align-items: center;

    .small-logo {
      display: none;
    }

    h2 {
      font-size: 3.4rem;
      line-height: 4.4rem;
      font-weight: 600;
      margin-bottom: 1.4rem;
    }

    .tip {
      font-size: 1.4rem;
      line-height: 1.6rem;
      opacity: 0.7;
      margin-bottom: 4rem;
    }

    .form {
      .subContent {
        display: flex;
        justify-content: space-between;

        font-size: 1.6rem;
        line-height: 1.9rem;
        margin-bottom: 4rem;

        .valueErrors {
          color: $error-color;
        }

        .link {
          font-weight: 600;
          color: $primary-color;
        }
      }

      .action {
        .btn {
          font-family: 'Gilroy', sans-serif;
          font-weight: 600;
          font-size: 1.6rem;
          line-height: 1.9rem;
          height: 6.3rem;
          text-transform: uppercase;
          border-radius: 0.8rem;

          &:first-child {
            background: $primary-color;
            border-color: $primary-color;

            &:hover {
              background-color: #0069d9;
              border-color: #0062cc;
            }

            &:disabled {
              color: rgba(255, 255, 255, 0.5);
              background: #4554d6;
              opacity: 0.5;
            }
          }

          &:last-child {
            border-color: $primary-color;
            color: $primary-color;

            &:hover {
              color: #fff;
              background: $primary-color;
            }
          }

          &:not(:last-child) {
            margin-bottom: 1.6rem;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $bp-medium) {
  .auth {
    .gradient {
      display: none;
    }

    .sign {
      .small-logo {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 10;
        height: 7.8rem;
        display: block;
        padding-left: 15px;
        display: flex;
        align-items: center;
        background-color: #fff;
      }

      .box {
        height: 100vh;
        display: flex;
        flex-direction: column;
      }

      h2 {
        font-size: 2.6rem;
        line-height: 2.9rem;
        font-weight: 600;
        margin-top: 11.5rem;
        margin-bottom: 1.6rem;
      }

      .tip {
        opacity: 1;
        margin-bottom: 7.2rem;
      }

      .form {
        display: flex;
        flex-direction: column;
        height: 100%;

        & > div[class^='form-group'] {
          margin-bottom: 0;
        }

        .subContent {
          flex-direction: column-reverse;
          justify-content: flex-start;
          gap: 0.8rem;
          margin-bottom: auto;
        }

        .action {
          margin-top: 0.8rem;
          margin-bottom: 3.2rem;

          .btn {
            &:not(:last-child) {
              margin-bottom: 0.8rem;
            }
          }
        }
      }
    }
  }
}
