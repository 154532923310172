@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-Regular.eot');
  src: local('☺'), url('./assets/fonts/Gilroy-Regular.woff') format('woff'),
    url('./assets/fonts/Gilroy-Regular.ttf') format('truetype'),
    url('./assets/fonts/Gilroy-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./assets/fonts/Gilroy-Semibold.eot');
  src: local('☺'), url('./assets/fonts/Gilroy-Semibold.woff') format('woff'),
    url('./assets/fonts/Gilroy-Semibold.ttf') format('truetype'),
    url('./assets/fonts/Gilroy-Semibold.svg') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Oswald';
  src: url('./assets/fonts/Oswald-SemiBold.eot');
  src: local('☺'), url('./assets/fonts/Oswald-SemiBold.woff') format('woff'),
    url('./assets/fonts/Oswald-SemiBold.ttf') format('truetype'),
    url('./assets/fonts/Oswald-SemiBold.svg') format('svg');
  font-weight: 600;
  font-style: normal;
}
