$bp-medium: 56.25em; // 900px
$bp-small: 37.5em; // 600px
$bp-smallest: 31.25em; // 500px

.logo {
  width: 6rem;
  height: 5.7rem;
  background: url(./assets/logo.svg) no-repeat center;
  background-size: contain;
  flex-shrink: 0;

  &.btwb {
    width: 12.8rem;
    background: url(./assets/btwb_logo_black.png) no-repeat center;
    background-size: contain;
  }
}

@media only screen and (max-width: $bp-medium) {
  .logo {
    width: 3.3rem;
    height: 3rem;

    &.btwb {
      width: 7.5rem;
      height: 2rem;
    }
  }
}
