.errorIndicator {
  font-family: 'Gilroy', sans-serif;
  font-weight: normal;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #0d1744;
  font-size: 1.6rem;
  background-color: #edf2ff;
  .boom {
    font-size: 2rem;
  }
}
