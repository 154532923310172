$bp-medium: 56.25em; // 900px

.workouts {
  max-width: 136rem;
  margin: 10rem auto;

  h2 {
    font-weight: 600;
    font-size: 4rem;
    line-height: 6rem;
    margin-bottom: 2.7rem;
  }

  h3 {
    text-align: center;
    font-size: 1.6rem;
    margin-bottom: 0;
    font-weight: normal;
  }

  & > [class^='Workout_workout'] {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

@media only screen and (max-width: $bp-medium) {
  .workouts {
    margin: 3.6rem auto 10rem;
    h2 {
      font-size: 2.6rem;
      line-height: 2.9rem;
      margin-bottom: 3.2rem;
    }
  }
}
