$bp-medium: 56.25em; // 900px

$color-text: #0d1744;
$color-border: #edf2ff;
$error-color: #eb5757;

.containerInput {
  display: flex;
  flex-direction: column;
  margin-bottom: 2.4rem;
  position: relative;

  .label {
    color: $color-text;
    font-size: 1.2rem;
    line-height: 1.4rem;
    margin-bottom: 1.2rem;
    text-transform: uppercase;
    opacity: 0.8;
    font-family: 'Gilroy', sans-serif;
    font-weight: normal;
    cursor: pointer;
    user-select: none;

    & > span {
      color: $error-color;
    }
  }

  .input {
    border-radius: 0;
    padding: 1.5rem 2.4rem;
    border: 2px solid $color-border;
    color: $color-text;
    font-size: 1.6rem;
    line-height: 1.9rem;
    font-weight: normal;

    &:focus {
      border: 2px solid #4554d6;
      outline: none;
    }

    &::placeholder {
      font-family: 'Gilroy', sans-serif;
      font-weight: normal;
      font-size: 1.6rem;
      line-height: 1.9rem;
      color: #000;
      opacity: 0.3;
    }
  }

  .error {
    position: absolute;
    bottom: -2.4rem;
    left: 0;
    right: 0;
    overflow: hidden;
    color: $error-color;
    line-height: 2.4rem;
    font-size: 1.4rem;
  }
}

@media only screen and (max-width: $bp-medium) {
  .containerInput {
    .label {
      opacity: 0.5;
      letter-spacing: 0.05rem;
      font-weight: 600;
      font-size: 1.6rem;
      margin-bottom: 1rem;
    }

    .input {
      border: 1px solid $color-border;
      border-radius: 0.8rem;
      padding: 1.7rem 1.6rem;
      font-size: 1.4rem;

      line-height: 1.6rem;

      &:focus {
        border: 1px solid #4554d6;
      }

      &::placeholder {
        font-size: 1.4rem;
        line-height: 1.6rem;
      }
    }
  }
}
