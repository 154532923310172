$color-bg: #f7f9ff;
$color-blue: #4554d6;

$bp-medium: 56.25em; // 900px

.workout {
  margin-bottom: 4rem;

  .title {
    font-size: 2.4rem;
    line-height: 3.2rem;
    font-weight: 600;
    margin-bottom: 2rem;
  }

  .card {
    background: $color-bg;
    padding: 2rem;

    h3 {
      font-size: 1.6rem;
      line-height: 3.6rem;
      text-align: left;
      font-weight: 600;
      text-transform: uppercase;
      opacity: 0.5;
      letter-spacing: 0.05rem;
      margin-bottom: 0.4rem;
    }

    p {
      font-size: 1.6rem;
      line-height: 2.4rem;
      margin-bottom: 0.4rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .reps {
      p {
        // color: $color-blue;
      }
    }

    .description {
      margin-top: 3rem;
      p {
        max-width: 56.5rem;
      }
    }

    .instructions {
      margin-top: 3rem;
      p {
        max-width: 56.5rem;
      }
    }
  }
}

@media only screen and (max-width: $bp-medium) {
  .workout {
    margin-bottom: 4.8rem;

    .title {
      font-size: 1.8rem;
      margin-bottom: 1.6rem;
    }

    .card {
      .reps {
        // display: none;
      }

      div[class*='col-md-'] {
        &:not(:last-child) {
          margin-bottom: 3.2rem;
        }
      }

      .description,
      .instructions {
        margin-top: 1rem;
        margin-bottom:  1.5rem;
      }
    }

    .smhidden {
      display: none;
    }
  }
}
