$bg-color: #fbfcff;
$color-text: #0d1744;
$color-text-light: #8489a1;
$color-orange: #f2994a;
$color-btn: #4554d6;
$color-green: #4ec19d;
$color-red: #E64925;

$bp-largest: 75em; // 1200px
$bp-large: 68.75em; // 1100px
$bp-medium: 56.25em; // 900px
$bp-small: 37.5em; // 600px
$bp-smallest: 31.25em; // 500px

.poster {
  position: relative;
  z-index: 1;
  height: 31vw;
  max-height: 50.8rem;
  background: url('./assets/banner_desktop-2500.png') no-repeat center;
  background-size: contain;
}

.container {
  display: flex;
  font-family: 'Gilroy', sans-serif;
  font-weight: normal;
  font-size: 1.6rem;
  color: $color-text;

  h3 {
    text-transform: uppercase;
    opacity: 0.5;
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 2.4rem;
    letter-spacing: 0.05rem;
    margin-bottom: 0.8rem;
  }
}

.right {
  flex: 1 1 40%;
  order: 2;
  background-color: $bg-color;
  padding: 4rem 1.5rem 18rem;

  .content {
    max-width: 48.2rem;
    margin: 0 auto;

    h2 {
      position: relative;
      font-weight: 600;
      font-size: 3rem;
      margin-bottom: 2.4rem;
      line-height: 6rem;
      padding-left: 5.2rem;

      &::before {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        content: '';
        display: inline-block;
        width: 3.2rem;
        height: 3.2rem;
        background: url('./assets/b_logo.png') no-repeat center;
        background-size: contain;
      }
    }

    p {
      font-size: 1.6rem;
      line-height: 2.4rem;
      margin-bottom: 0.4rem;

      &.soon {
        padding-left: 1.6rem;
        margin-bottom: 2rem;
        text-transform: capitalize;
        position: relative;

        &::before {
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          content: '';
          display: inline-block;
          width: 0.8rem;
          height: 0.8rem;
          background-color: $color-red;
          border-radius: 50%;
        }
      }

      &.light {
        color: #8489a1;
        text-transform: capitalize;

        span {
          color: $color-text;
        }
      }
    }

    .textBox {
      margin-bottom: 2rem;

      &.badge {
        .icon {
          width: 6.8rem;
          height: 8.9rem;
          background: url('./assets/grace-4.png') no-repeat center;
          background-size: contain;
          margin-bottom: 0.9rem;

          & + p {
            max-width: 20.7rem;
          }
        }
      }

      &.contact {
        a {
          font-family: 'Gilroy', sans-serif;
          font-weight: normal;
          font-size: 1.6rem;
          line-height: 2.4rem;
          color: #4554d6;
        }
      }
    }

    .pdfs {
      margin-bottom: 2rem;
    }

    .action {
      display: flex;
      margin-bottom: 4rem;
      margin-top: 4rem;

      .btn {
        font-family: 'Gilroy', sans-serif;
        font-weight: 600;
        font-size: 2rem;
        line-height: 2.4rem;
        text-transform: uppercase;
        padding: 2rem 5rem;
        height: 6.4rem;
        border-radius: 0.8rem;

        &:first-child {
          background: $color-btn;
          border-color: $color-btn;

          &:hover {
            background-color: #0069d9;
            border-color: #0062cc;
          }
        }

        &:last-child {
          // border-color: $color-btn;
          // color: $color-btn;

          &:hover {
            // color: #fff;
            // background: $color-btn;
          }
        }

        &:not(:last-child) {
          margin-right: 1.6rem;
        }
      }
    }
  }
}

.left {
  flex: 1 1 60%;
  order: 1;
  min-width: 50%;
  background-image: radial-gradient(
    73.45% 73.45% at 5% 5%,
    rgba(69, 84, 214, 0.11) 0%,
    rgba(69, 84, 214, 0) 100%
  );
  padding: 4rem 8.7rem 18rem 11.3rem;
  display: flex;
  justify-content: flex-end;

  .content {
    max-width: 63rem;

    h2 {
      font-weight: 600;
      font-size: 4rem;
      margin: 4rem 0 2rem;
      line-height: 6rem;
    }

    p {
      line-height: 2.4rem;
      margin-bottom: 4rem;

      &.formobile {
        display: none;
        margin-top: 3.2rem;
        margin-bottom: 10rem;
      }
    }

    .howBlock {
      ul {
        margin-left: 14px;
        margin-bottom: 4rem;
      }
    }

    .video {
      position: relative;

      .play {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 7.8rem;
        height: 7.7rem;
        border: none;
        cursor: pointer;
        // background: url('../../assets/img/play.svg') no-repeat center;
        background-size: contain;
        transition: all 0.2s;

        &:focus {
          outline: none;
        }

        &:active {
          transform: translate(-50%, -49%);
        }

        &:hover {
          // opacity: 0.7;
        }
      }
    }

    .cellar {
      display: none;
      background-color: $bg-color;
      padding: 6rem 1.5rem;

      .host {
        margin-bottom: 3.2rem;
      }

      .textBox {
        margin-bottom: 3.2rem;

        &.badge {
          .icon {
            width: 6.8rem;
            height: 8.9rem;
            background: url('./assets/grace-4.png') no-repeat center;
            background-size: contain;
            margin-bottom: 0.9rem;

            & + p {
              max-width: 20.7rem;
            }
          }
        }

        &.contact {
          margin-bottom: 0;

          a {
            font-family: 'Gilroy', sans-serif;
            font-weight: normal;
            font-size: 1.6rem;
            line-height: 2.4rem;
            color: #4554d6;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $bp-largest) {
  .left {
    padding: 4rem 1.5rem 18rem;
  }
  .right {
    padding: 4rem 1.5rem 18rem;

    .content {
      max-width: 100%;
    }
  }
}

@media only screen and (max-width: $bp-large) {
  .right {
    .content {
      h2 {
        font-size: 3.6rem;
      }

      .action {
        .btn {
          font-size: 1.7rem;
        }
      }
    }
  }
}

@media only screen and (max-width: $bp-medium) {
  .container {
    flex-wrap: wrap;
  }
  .right,
  .left {
    order: 0;
    flex-basis: 100%;
  }

  .left {
    padding: 2.4rem 1.5rem 0;
    justify-content: center;
    background-image: none;

    .content {
      p {
        &.formobile {
          display: block;
        }
      }

      .cellar {
        display: block;
      }
    }
  }

  .right {
    padding: 4rem 1.5rem 6rem;

    .content {
      max-width: 63rem;

      h2 {
        font-size: 2.6rem;
        margin-bottom: 2.1rem;
      }

      & > h3 {
        display: none;
      }

      .textBox {
        &.division {
          margin-bottom: 0;

          p {
            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        &.badge {
          display: none;
        }

        &.contact {
          display: none;
        }
      }
      
      .pdfs {
        margin-bottom: 0rem;
        margin-top: 2rem;
      }

      .host {
        display: none;
      }

      .action {
        flex-wrap: wrap;
        margin-top: 3.2rem;
        margin-bottom: 4.8rem;

        .btn {
          font-size: 1.6rem;
          width: 100%;

          &:not(:last-child) {
            margin-right: 0;
            margin-bottom: 0.8rem;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $bp-small) {
  .poster {
    height: 25.2rem;
    background: url('./assets/banner_mobile.png') no-repeat left;
    background-size: cover;
  }

  .left {
    .content {
      .cellar {
        margin-right: -1.5rem;
        margin-left: -1.5rem;
      }
    }
  }
}

@media only screen and (max-width: $bp-smallest) {
  .left {
    .content {
      .video {
        height: 36rem;
        margin-right: -1.5rem;
        margin-left: -1.5rem;
        background: url('./assets/shirt-details.jpg') no-repeat center;
        background-size: contain;
        .big {
          display: none;
        }
      }
    }
  }
}
