$color-drawer: #fff;
$color-link: #0d1744;
$color-link-active: #4554d6;

.drawer {
  position: fixed;
  top: 0;
  z-index: 99;
  width: 100%;
  height: 100vh;
  background-color: $color-drawer;
  padding: 2.8rem 1.5rem;
  text-align: center;
  right: -100%;
  overflow: auto;
  transition: right 400ms ease-out;

  &.open {
    right: 0;

    .top {
      opacity: 1;
    }
  }

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    opacity: 0;
    transition: opacity 0.2s ease-in 0.2s;

    .logoContainer {
      cursor: pointer;
    }

    .close {
      width: 2.4rem;
      height: 2.4rem;
      background: url('./assets/close.svg') no-repeat center;
      background-size: contain;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        opacity: 0.6;
      }
    }
  }

  .links {
    margin-top: 6.3rem;

    .sign {
      font-family: 'Gilroy', sans-serif;
      font-weight: 600;
      font-size: 1.6rem;
      text-transform: uppercase;
      border-radius: 8px;
      height: 6.3rem;
      margin-bottom: 4rem;
      background: #4554d6;
      border-color: #4554d6;

      &:hover {
        background-color: #0069d9;
        border-color: #0062cc;
      }
    }

    & > a {
      text-decoration: none;
      display: block;
      &:not(:last-child) {
        margin-bottom: 2.4rem;
      }
    }

    .link {
      font-family: 'Gilroy', sans-serif;
      font-weight: 600;
      font-size: 2.6rem;
      text-transform: uppercase;
      color: $color-link;
      height: 3.8rem;
      line-height: 3.8rem;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        color: $color-link-active;
      }
    }
  }
}
