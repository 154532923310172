$bp-medium: 56.25em; // 900px

$primary-color: #4554d6;
$color-text: #0d1744;
$color-bg: #fbfcff;
$color-border: #edf2ff;
$error-color: #e64925;

.shirt {
  position: fixed;
  z-index: 90;
  top: 0;
  min-height: 100vh;
  width: 100%;
  background-color: #fff;
  display: flex;
  color: $color-text;

  .left {
    position: relative;
    max-width: 61.7rem;
    flex-grow: 1;
    overflow: hidden;
    background: $color-bg;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .big-logo {
      position: absolute;
      top: 2rem;
      left: 50%;
      transform: translateX(-50%);
    }

    .shirtBig {
      max-width: 37.7rem;

      .icon {
        width: 100%;
        height: 39.6rem;
        background: url('../../assets/img/shirt.png') no-repeat center;
        background-size: cover;

        & + p {
          text-align: center;
          margin-top: 4rem;
          opacity: 0.5;
          margin-bottom: 0;
        }
      }
    }
  }

  .right {
    position: relative;
    flex-grow: 1;

    display: flex;
    align-items: center;

    .small-logo {
      display: none;
    }

    .shirtSmall {
      display: none;
    }

    .formBox {
      h2 {
        font-size: 3.4rem;
        line-height: 4.4rem;
        font-weight: 600;
        margin-bottom: 2rem;
      }

      .tip {
        font-size: 1.6rem;
        line-height: 2.4rem;
        margin-bottom: 4rem;
      }

      .price {
        margin-bottom: 4rem;

        h3 {
          font-weight: normal;
          text-transform: uppercase;
          font-size: 1.2rem;
          line-height: 1.4rem;
          opacity: 0.8;
          margin-bottom: 1.2rem;

          & + p {
            font-weight: normal;
            font-size: 1.6rem;
            line-height: 1.9rem;
            margin-bottom: 0;
          }
        }
      }

      .form {
        .size {
          margin-bottom: 4rem;

          .title {
            font-size: 1.2rem;
            line-height: 1.4rem;
            text-transform: uppercase;
            opacity: 0.8;
            margin-bottom: 1.2rem;
            cursor: pointer;
            user-select: none;

            span {
              color: $error-color;
              font-size: 1.2rem;
            }
          }

          .sizeItem {
            div[class^='sizeItem__control'] {
              border-color: $color-border;
              border-width: 1px;
              border-radius: 0;
              height: 5rem;
            }

            div[class^='sizeItem__placeholder'] {
              font-size: 1.6rem;
              line-height: 1.9rem;
              color: $color-text;
              opacity: 0.3;
            }
          }
        }

        .btn {
          font-family: 'Gilroy', sans-serif;
          font-weight: 600;
          font-size: 1.6rem;
          line-height: 1.9rem;
          height: 6.3rem;
          border-radius: 0.8rem;
          background: $primary-color;
          border-color: $primary-color;

          &:hover {
            background-color: #0069d9;
            border-color: #0062cc;
          }

          &:focus {
            outline: none;
          }
        }

        .refusal {
          margin-top: 3.4rem;
          text-align: center;

          span {
            font-weight: 600;
            opacity: 0.5;
            line-height: 1.9rem;
            cursor: pointer;
          }

          .warning {
            p {
              font-weight: 600;
              color: $error-color;
              font-size: 1.6rem;
              line-height: 1.9rem;
              margin-bottom: 1.6rem;
            }

            .action {
              display: flex;
              justify-content: center;

              .button {
                font-family: 'Gilroy', sans-serif;
                font-weight: 600;
                font-size: 1.6rem;
                line-height: 1.9rem;
                height: 5.5rem;
                width: 21rem;
                border-radius: 0.8rem;
                padding: 1.8rem;

                &:first-child {
                  background: $error-color;
                  border-color: $error-color;

                  &:hover {
                    background-color: rgba($error-color, 0.9);
                    border-color: rgba($error-color, 0.9);
                  }
                }

                &:last-child {
                  border-color: $primary-color;
                  color: $primary-color;

                  &:hover {
                    color: #fff;
                    background: $primary-color;
                  }
                }

                &:not(:last-child) {
                  margin-right: 1.6rem;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: $bp-medium) {
  .shirt {
    .left {
      display: none;
    }

    .right {
      .small-logo {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 10;
        height: 7.8rem;
        padding-left: 15px;
        display: flex;
        align-items: center;
        background: $color-bg;
      }

      .box {
        height: calc(100vh - 7.8rem);
        display: flex;
        flex-direction: column;
        margin-top: 7.8rem;

        .shirtSmall {
          display: flex;
          flex-direction: column;
          align-items: center;
          background: $color-bg;
          margin-left: -15px;
          margin-right: -15px;

          .icon {
            width: 26.8rem;
            height: 28.15rem;
            background: url('../../assets/img/shirt.png') no-repeat center;
            background-size: cover;
            margin-top: 3.4rem;

            & + p {
              font-size: 1.6rem;
              line-height: 2.4rem;
              text-align: center;
              margin-top: 2.4rem;
              margin-bottom: 6rem;
              max-width: 26.8rem;
            }
          }
        }

        .formBox {
          h2 {
            font-size: 2.6rem;
            line-height: 3.4rem;
            margin-top: 6rem;
            margin-bottom: 1.2rem;
          }

          .tip {
            margin-bottom: 3.2rem;
          }

          .price {
            margin-bottom: 3.2rem;

            h3 {
              font-weight: 600;
              text-transform: uppercase;
              font-size: 1.6rem;
              line-height: 1.4rem;
              letter-spacing: 0.05rem;
              opacity: 0.5;

              & + p {
                font-size: 2.6rem;
                line-height: 3.1rem;
              }
            }
          }

          .form {
            .size {
              margin-bottom: 4.8rem;

              .title {
                font-size: 1.6rem;
                opacity: 0.5;
                margin-bottom: 1.6rem;
                letter-spacing: 0.05rem;

                span {
                  font-size: 1.6rem;
                }
              }

              .sizeItem {
                div[class^='sizeItem__control'] {
                  border-radius: 0.8rem;
                }
              }
            }

            .btn {
              text-transform: uppercase;
            }

            .refusal {
              margin-top: 2.4rem;
              margin-bottom: 3.2rem;

              .warning {
                p {
                  max-width: 29.1rem;
                  display: inline-block;
                }

                .action {
                  .button {
                    font-size: 1.4rem;
                    line-height: 1.7rem;
                    height: 6.3rem;
                    padding: 2.3rem 2rem;
                    text-transform: uppercase;

                    &:first-child {
                      width: 14.1rem;
                    }

                    &:last-child {
                      width: 19.5rem;
                    }

                    &:not(:last-child) {
                      margin-right: 1.6rem;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
